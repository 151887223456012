<template>
  <form v-if="form" class="space-y-6 w-full pb-3">

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="col-span-1">
        <Input v-model="form.name" :errors="errors.name" placeholder="Nosaukums" />
      </div>
      <div class="col-span-1">
        <Input v-model="form.short_name" :errors="errors.short_name" placeholder="Saīsinājums" />
      </div>
    </div>

    <div class="">
      <div class="flex justify-end">
        <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="hideEditItemForm">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import Input from "@/components/Components/Input";
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "EditRegion",
  components: {
    Input,
    Loading,
  },
  props: ["item"],
  data: () => ({
    form: null,
  }),
  created() {
    this.$store.dispatch("getRegionInputData");
  },
  mounted() {
    this.form = this.item
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors",
    }),
    formValidation() {
      return {
        name: {
          rules: ['required']
        },
        short_name: {
          rules: ['required']
        },
      }
    }
  },
  methods: {
    hideEditItemForm() {
      this.$store.dispatch("getUnits");
      this.$store.dispatch("clearEditableItem");
      this.$store.dispatch("setErrors", {});
    },
    submit() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("updateUnit", {
          id: this.item.id,
          name: this.form.name,
          short_name: this.form.short_name,
        });
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>