<template>
  <Content>
    <template v-slot:topbar>
      <AddMeasureUnit />
    </template>

    <template v-slot:content>
      <template v-if="units && units.data.length > 0">
        <div class="sm:rounded-md mb-3">
          <ul role="list" class="">
            <template v-for="item in units.data" :key="item.id">
              <SingleMeasureUnit :item="item" />
            </template>
          </ul>
        </div>

        <Pagination :meta="units.meta" :onPageChange="onPageChange" />
      </template>

      <template v-else>
          <p class="py-6 text-center dark:text-gray-300">
            Diemžēl nekas netika atrasts
          </p>
      </template>
    </template>
  </Content>
</template>

<script>
import { debounce } from 'vue-debounce'
import {mapGetters} from "vuex";
import Search from "@/services/Helpers/search";
import AddMeasureUnit from "@/components/Settings/CompanySettings/MeasureUnits/AddMeasureUnit";
import SingleMeasureUnit from "@/components/Settings/CompanySettings/MeasureUnits/SingleMeasureUnit";
import Pagination from "@/components/Components/Pagination";

export default {
  name: "MeasureUnits",
  components: {
    AddMeasureUnit,
    SingleMeasureUnit,
    Pagination,
  },
  created() {
    this.$store.dispatch("setDataLoading", true)
  },
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('resetSearch')
    const s = Search.getSearchQuery();
    if (s) {
      this.$store.dispatch('changeSearch', s)
      this.searchUnits()
    } else {
      this.$store.dispatch('getUnits', Search.getCurrentPage())
    }
  },
  computed: {
    ...mapGetters({
      units: 'units',
      formsForDisplay: 'formsForDisplay',
      editableItem: 'editableItem',
      search: 'search',
    })
  },
  watch: {
    search: function () {
      this.searchUnits()
    }
  },
  methods: {
    searchUnits: debounce(function () {
      this.$store.dispatch('changeSearch', this.search)
      this.$store.dispatch('getUnits', Search.getCurrentPage())
    }, 500),
    onPageChange(page) {
      this.$store.dispatch('getUnits', page);
      Search.changePage(this.banks.meta.current_page)
    },

    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    hideAddBankForm(){
      this.$store.dispatch('removeAllFormsForDisplay')
    },
    editItem(item){
      this.$store.dispatch('setEditableItem', item)
    },
    deleteUnit(itemId){
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteUnit', itemId)
      }
    }
  }
}
</script>